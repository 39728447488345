import { useState, useEffect } from 'react'
import HttpService from './HttpService'

const UseFormValidation = (initialState, validate) => {
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [isSubmittin, setSubmitting] = useState(false)


useEffect(() => {
  if (isSubmittin) {
    const noErrors = Object.keys(errors).length === 0
    if(noErrors) {
      const data = {
        "email": values.email,
        "name": values.name,
        "campaign": {
          "campaignId": "Yr"
        }
      }

      HttpService.post('https://api.feitodeiridium.com.br/api/v1/contact', data);

      console.log('success, lead insertion')
    } else {
      setSubmitting(false)
    }
  }
  }, [errors, isSubmittin, values.email, values.name, values.phone])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  const handleBlur = () => {
    const validationErrors = validate(values)
    setErrors(validationErrors)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const validationErrors = validate(values)
    setErrors(validationErrors)
    setSubmitting(true)

  }

  return {handleSubmit ,handleChange, values, handleBlur, errors, isSubmittin }
}

export default UseFormValidation
