import React from 'react'
import style from './Header.module.css'

import Logo from '../../assets/logo.png'

const Header = () => (
  <div className={style.header}>
    <img src={Logo} alt="logo"/>
    <h3 className={style.data}>de 09 a 15 de novembro</h3>
    <h2 className={style.objective}>EM UMA MARATONA DE 7 LIVES VOU REVELAR OS SEGREDOS PARA VOCÊ CORRER ATRÁS DOS PREJUÍZOS DA QUARENTENA E RECUPERAR SEU SHAPE PRO VERÃO!</h2>

  </div>
)

export default Header
