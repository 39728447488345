import React from 'react'
import style from './Calendar.module.css'
import Cards from '../cards/Cards'
import ScrollButton from '../scrollButton/ScrollButton'

const Calendar = () => (
  <section className={style.section}>
    <div className={style.div}>
    <h3 className={style.title}>Calendário das aulas ao vivo</h3>
    <span className={style.span}></span>
    <Cards
      number='1'
      date='09/11/2020'
      title='PREPARATIVOS PARA UM ÓTIMO TREINO'
      text={['Alimentação: como potencializar seu treino', 'Funcionamento da mente', 'Ambiente de treino', 'O segredo para um bom treino']}
    />
    <Cards
      number='2'
      date='10/11/2020'
      title='COMO OS MÚSCULOS FUNCIONAM E SÃO CONSTRUÍDOS'
      text={['Tipos de contração e fibras', 'Microlesões e como não se machucar', 'Teoria do estresse', 'Teoria da supercompensação', 'Alimentação para fazer acontecer']}

    />
    <Cards
      number='3'
      date='11/11/2020'
      title='MUSCULAÇÃO: MUITO ALÉM DE MÚSCULOS'
      text={['O corpo é uma máquina biologicamente sincronizada', 'Melhora do perfil lipídico', 'Melhora da sensibilidade a insulina', 'Diminuição ou retardamento de doenças', 'O que muda em sua vida']}

    />
    <Cards
      number='4'
      date='12/11/2020'
      title='TUDO QUE VOCÊ PRECISA SABER SOBRE NUTRIÇÃO'
      text={['Nosso corpo é uma máquina que precisa de combustível', 'Como assim calórias', 'O que são micronutrientes', 'divisão de macros', 'Como calcular o SEU valor calórico', 'Montagem de uma refeição', 'A dieta de um atleta em sua preparação']}
    />
    <Cards
      number='5'
      date='13/11/2020'
      title='BIOMECÂNICA:  ARTICULAÇÕES E MÚSCULOS EM SINCRONIA'
      text={['Como as articulações e músculos trabalham com peso.', 'Dicas que vão mudar o seu treino', 'Quando o corpo "rouba" sem que percebamos.', 'A cabeça de um atleta durante o treino']}
    />
    <Cards
      number='6'
      date='14/11/2020'
      title='REPETIÇÕES, SÉRIES E CARGA: QUAL O VOLUME IDEAL'
      text={['Quantas repetições fazer para hipertrofia?', 'Quantas séries são necessárias para gerar o melhor estímulo?', 'Peso: quanto mais, melhor?!', 'O que muda em diferentes fazes de preparação de um atleta?']}
    />
    <Cards
      number='7'
      date='15/11/2020'
      title='LÁERCIO MONTA SEU TREINO!'
      text={['Por que se divide o treino?', 'AB, ABC, ABCD...TUDO sobre divisão de treinos', 'Quantas vezes treinar por semana?', 'Montaremos o treino de participantes da semana de lives AO VIVO!']}
    />
  </div>

  <ScrollButton/>
  </section>
)

export default Calendar
