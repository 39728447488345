import React from 'react';
import style from './MainContent.module.css';

const MainContent = () => (
  <div className={style.video}>
    <div className={style.videoDiv}>
      <iframe className={style.embed} type="text/html" src="https://player.vimeo.com/video/475209775" width='100%' height='100%' frameborder='0' title='course presentation'/>
    </div>

    <h1 className={style.objectiveTime}>VAMOS ACELERAR SEUS RESULTADOS E REVOLUCIONAR SEU SHAPE SEM VOCÊ GASTAR UM REAL, QUE TAL?</h1>

    <p className={style.videoText}>
      Na maratona de lives Em Busca do Shape Perdido, vou te mostrar um jeito fácil e descomplicado de não só recuperar seu shape, mas atualizar para um versão ainda melhor, em poucas semanas! Vem comigo !
    </p>

    <ul className={style.list}>
      <li>100% online</li>
      <li>100% gratuíto</li>
      <li>100% ao vivo</li>
    </ul>

    <p className={style.formText}>UMA EXPERIÊNCIA DIFERENTE DE TUDO QUE VOCÊ JÁ VIU:</p>

    <p className={style.videoText}>
    Revolucionar é a palavra de ordem dos conteúdos apresentados durante a semana.
Serão 7 lives, com especialistas para cada tema e convidados especiais!
Revolucione a forma de aprender, de interagir. Revolucione seu shape!
    </p>

    <p className={style.formText}>Faça seu cadastro abaixo para receber a sequência de lives:</p>
  </div>

)

export default MainContent
