import React from 'react'
import style from './CourseInfo.module.css'

const CourseInfo = () => (
  <div className={style.courseInfoDiv}>
    <h3 className={style.title}>Essa é a chance de sair da zona de conforto, dar a volta por cima e revolucionar seu shape!</h3>
    <h3 className={style.title}>SE LIGA NO QUE PREPARAMOS PARA VOCÊ:</h3>
    <span className={style.separator}></span>
    <p className={style.text}>
    Selecionamos um time de especialistas imbátivel para trazer todo o conhecimento e expertise de anos de estudos e prática para te ensinar tudo o que você precisa saber para construir um shape de respeito. Todo dia será abordado um tema diferente por diferentes profissionais, em lives totalmente dinâmicas para você aprender se divertindo, você vai se Surpreender !
    </p>
    <p className={style.text}>
     Confia na gente que você vai mudar !
    </p>
  </div>
)

export default CourseInfo
