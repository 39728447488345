import React from 'react';
import { render } from 'react-snapshot';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TF83LKT'
}

TagManager.initialize(tagManagerArgs)

render(<App />, document.getElementById('root'));

serviceWorker.unregister();
